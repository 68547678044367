import React, { useState, useEffect } from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';
import { client} from '../prismic-configuration'
import Prismic from 'prismic-javascript'

const Positions = (props) => {
    // console.log(props)
    const [position, setPosition] = useState(null)

    const uid="kotahi"

    // Get the page document from Prismic
    useEffect(() => {
        const fetchProjects = async () => {
            const response = await client.query(
                Prismic.Predicates.at('document.type', 'position')
            )
            if (response) {
                // console.log(response.results)
                return setPosition(response.results)
            }
        }
        fetchProjects()
    }, [uid]) // Skip the Effect hook if the UID hasn't changed
     
    //"team col-xs-12 col-lg-6 col-xl-4" 

    if(position) {
    return (
        <div className="position-list">
            <div className="row">
                <div className="col-md-9 offset-md-2 col-xl-9 offset-xl-3">
                    <div className="row wrapper">
                        {position.map((item, index)=>{
                            return (
                                <div className={index===0 ? "team  col-md-4 pull-left"  : "team col-md-4"} key={index}>
                                    <RichText render={item.data.job_title} linkResolver={linkResolver} />
                                    <div className="contact-block">
                                        {item.data.contact_name ? 
                                        <h5 className="contact"><span className="prefix">Contact:</span> {item.data.contact_name} / {item.data.contact_email}</h5>
                                        : null }
                                        {item.data.location ? 
                                        <h5 className="location"><span className="prefix">Location:</span> {item.data.location}</h5>
                                        :
                                        null }
                                    </div>
                                    <div className="job-description-area">
                                        <div className="description-content">
                                            <RichText render={item.data.job_description} linkResolver={linkResolver} />
                                        </div>
                                        <div className="read-more"><a href={item.data.link.url}>Link</a></div>
                                    </div>    
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
    }
    else {
        return null
    }
}


export default Positions;
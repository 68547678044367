import React, { useRef }from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const ProjectImageRollover = (props) => {

    const imageRef = useRef(null)
    const toggleRollOver = (e) => {
        e.currentTarget.childNodes[1].classList.toggle("open")
    }
    return (
        <div className="content-area" ref={imageRef} onMouseOver={(e)=>{toggleRollOver(e)}} onMouseOut={(e)=>{toggleRollOver(e)}}  onClick={()=>{window.location.href=props.url}}>
            <div className="image-area">
                <img src={props.data.image.url} alt={props.data.image.alt} />
            </div>
            <div className={"rollover-content "+props.state}>
                <RichText render={props.data.project_name} linkResolver={linkResolver} />  
                {/* <RichText render={props.data.key_aspects} linkResolver={linkResolver} />                */}
            </div>
        </div>
    );
}

export default ProjectImageRollover;
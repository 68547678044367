import React, {useRef, useState, useEffect }from 'react';
import { ProjectResultImageRollover } from './index'
import {RichText, linkResolver} from 'prismic-reactjs';
import { client} from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import _, { identity } from 'lodash'

const Projects = (props) => {
    // console.log(props)
    const [projects, setProjects] = useState(null)
    const [projectList, setProjectList] = useState([])
    const [ignore, setIgnore] = useState(false);
    const filter = useRef(null);
    const currentFilter = useRef(null);

    const toggleFilter = (type) =>{
        filter.current.classList.toggle("open");
    }

    const filterProjects= (e, preset)=> {
        let filteredList=[];
        let filteredSet=[];
        let filterBy = preset

        if(!preset || preset === "" || preset === null) {
            filterBy = e.currentTarget.innerText;
        }

        currentFilter.current.innerHTML = filterBy;

        projects.map((project, index) => {
            for(let k=0; k<project.tags.length; k++) {
                if(project.tags[k].toLowerCase() === filterBy.toLowerCase() ) {
                    filteredSet.push(project)
                }
            }
            return null;
        });

        setProjectList(filteredSet);
    }

    const uid="kotahi"

    // Get the page document from Prismic
    useEffect(() => {
        const fetchProjects = async () => {
            const response = await client.query(
                Prismic.Predicates.at('document.type', 'projects'), { 
                    pageSize : 100,
                    orderings: '[my.projects.publication_date desc]' }
            )
            if (response) {
                // console.log(response)
                return setProjects(response.results)
                
            }
        }
        fetchProjects()

    }, [uid]) // Skip the Effect hook if the UID hasn't changed

    if(projects) {

        if(projects?.length > 0 && props.data.primary.filter_projects && ignore === false) {
            setTimeout(()=>{
            filterProjects(null, props.data.primary.filter_projects); setIgnore(true)},
            100);
        }

        // console.log(projects)
        let taglist=[];
        let rowList=[];
        projects.map((item, index) => {
            item.tags.map((tag, index) =>{
                taglist.push(tag)
                return null
            })
            rowList.push(item)
            return null
        });
        
       
       
        const tagbuttons =  _.uniq(taglist).sort();


        if(projectList && projectList.length > 0 ) {
            rowList = projectList;
        }

        // console.log("d: ", props.data.primary.filter_projects.toLowerCase())

        return (
            <div className="projects">
                <div className={"row filter-container " + props.sticky}>
                    <div className="col-md-6 offset-md-3 col-xl-6 offset-xl-3 filter">
                        <div className="project-filter">
                            <div className="filter-text" onClick={toggleFilter} >Filter by type: <span className="current" ref={currentFilter}><span>All</span></span>
                            </div>
                            <div className="filter-list" ref={filter}>
                                <ul className="dropdown" >
                                    {tagbuttons.map((filter, index) => {
                                        return (
                                            <li onClick={(e)=> {filterProjects(e, null); toggleFilter()}}>{filter}</li>
                                        )
                                    })}
                                    <li onClick={(e)=> { filterProjects(e, null); toggleFilter()}}>All</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="project-results">
                        <div className="row">
                            {rowList.map((item, index)=>{
                                return(
                                    <div className="col-lg-3" key={index}>
                                        <div className="project-panel">
                                        <div className="wrapper full">
                                            <div>
                                                <ProjectResultImageRollover data={item.data} url={'/projects/'+item.uid}/>
                                                    <div className="caption">
                                                    {item.data ? 
                                                        <p><a href={'/projects/'+item.uid}>PROJECT: {RichText.asText(item.data.body[0].primary.client)}</a></p>
                                                    : null }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    <h3 className="contact-link"><a href="/get-in-touch">Contact us about your project</a></h3>
                </div>
            </div>
        );
    }
    else {
        return null
    }
}

export default Projects
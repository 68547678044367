// In src/pages/Page.js
import React, { useEffect, useState,Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { client} from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import {ProjectBanner, ProjectImage, Header, Footer, RelatedProjects} from '../components'

const Section = ({ match }) => {
  const [doc, setDocData] = useState(null)
  const [core, setCoreData] = useState(null)
  const [navItems, setNavItems] = useState(null)
  const [sticky, setSticky] = useState("")
  const uid = match.params.uid
  // Get the page document from Prismic
  useEffect(() => {

     // scrolly rolly magic
     document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 100){
        setSticky("sticky")
      } else {
        setSticky("")
      }
    })

    const fetchCore = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('header_footer', 'header-footer-menu')
      if (result) {
        // We use the State hook to save the document
        // console.log('core: ', result)
        return setCoreData(result)
      } else {
        // Otherwise show an error message
        // console.log('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchCore()
    const fetchDoc = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('projects', uid)
      if (result) {
        // console.log('doc: ', result)
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        // console.log('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchDoc()
    const fetchNavItems = async () => {
      const response = await client.query(
        Prismic.Predicates.at('document.type', 'section'), { orderings: '[my.section.page_order]'}
      )
      if (response) {
        return setNavItems(response)
      }
    }
    fetchNavItems()
  }, [uid]) // Skip the Effect hook if the UID hasn't changed

  if (core && doc && navItems) {
    let dataSet=[];

    doc.data.body.map((component, index) =>{
      switch(component.slice_type) {
        case "project_banner":
          dataSet.push(<ProjectBanner data={component} tags={doc.tags} key={index} />)
        break
        case "project_image":
          dataSet.push(<ProjectImage data={component} key={index} />)
        break
        case "related_projects":
          dataSet.push(<RelatedProjects data={component} key={index} />)
        break
        default:
      }
    })

    return (
      <Fragment>
        <Helmet>
          <meta property="og:title" content={doc.data.seo_title}/>
          <meta property="og:description" content={doc.data.seo_description} />
          <meta property="og:image" content={doc.data.seo_image.url}/>
          <meta property="og:url" content={window.location.href}/>
          <meta name="twitter:card" content={doc.data.seo_image.alt} />
          <meta property="og:site_name" content={doc.data.seo_title}/>
          <meta name="twitter:image:alt" content={doc.data.seo_image.alt}/>
          <title>{doc.data.seo_title}</title>
        </Helmet>
        <div className="wrapper">
          <Header data={core} nav={navItems} stickyState={sticky}/>
          {dataSet.map((module, index)=>{
            // console.log(module)
            return module
          })}
          <div className="bottom-contact-link">
            <h3 className="contact-link"><a href="/get-in-touch">Contact us about your project</a></h3>
          </div>
          <div className="spacer"></div>
          <Footer data={core} />
        </div>
      </Fragment>
    )
  } 
  else {
      return null
  }
}

export default Section
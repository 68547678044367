import React from 'react';

const Footer = (props) => {
    // console.log(props)

    const locations = props.data.data.body[1];
    const contacts = props.data.data.body[0];
    const footerlinks = props.data.data.body[2];
    
    return (
        <div className="footer-panel">
            <div className="row">
                <div className="col-md-6 offset-md-6 col-lg-5 offset-lg-7">
                    <div className="footer-logo-wrapper"><img src={props.data.data.logo.url} alt={props.data.data.logo.url} /></div>
                    <div className="row">
                        <div className="col-7 col-sm-6">
                            <ul className="footer-contact">
                                {contacts.items.map((item, index) =>{
                                    if(item.link) {
                                        return (
                                            <li key={index+10}><a href={item.link.url} key={index}><img className="contact_icon"  src={item.link_icon.url} alt=""/>{item.link_text && item.link_text[0] ? item.link_text[0].text : null}</a></li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                        <div className="col-5 col-sm-6">
                            <ul className="footer-locations">
                                {locations.items.map((item, index) =>{
                                    return (
                                        <li key={index+10}><img className="location_icon" src={item.location_icon.url} alt=""/>{item.location}</li>
                                    )
                                })}
                            </ul>
                            <ul className="terms-links">
                                {footerlinks.items.map((item, index) =>{
                                    // console.log(item)
                                    if(item.link) {

                                        return (
                                            <li key={index+20}><a href={item.link.uid} key={index}>{item.link_text}</a></li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div className="col-md-3">
                    
                </div>
            </div>
        </div>  
    );
}

export default Footer;
import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const TwoColumnPanel = (props) => {
        console.log(props)
        return (
        <div className="two-column-panel">
            <div className="row">
                <div className={"col-md-6 panel-left " + (props.data.primary.left_hand_background === "blue" ? "" : "white")}>
                    <img className="background" src={props.data.primary.background_image?.url} alt="" />
                    <RichText render={props.data.primary.left_hand_column} linkResolver={linkResolver} />
                    <div className={'right-float '+ props.data.primary.floating_shape?.url}></div>
                </div>
                <div className={"col-md-6 panel-right " + (props.data.primary.left_hand_background === "blue" ? "" : "white")}>
                    <RichText render={props.data.primary.right_hand_column} linkResolver={linkResolver} />
                    <div className="read-more"><a href={props.data.primary.link?.url ? props.data.primary.link.url : props.data.primary.link?.uid}>{props.data.primary.link_text}</a></div>
                    <div className={'right-float '+ props.data.primary.floating_shape_right?.url}></div>
                </div>
            </div>
        </div>   
    );
}

export default TwoColumnPanel;



import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';


const ProjectBanner = (props) => {
    // console.log(props)

    return (
        <div className="project-banner">
            <div className="wrapper">
                <div className="row">
                    <div className="col-12">
                        <img src={props.data.primary.image.url} alt={props.data.primary.image.alt}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 offset-lg-5 col-md-7 offset-md-5">
                        <ul>
                            <li><img src="/images/triangle-small-white.svg" alt=""/> <span className="prefix">Project: </span>{props.data.primary.client[0].text}</li>
                            <li><img src="/images/semi-circle-small-white.svg" alt=""/> <span className="prefix">Location: </span>{props.data.primary.location}</li>
                            <li><img src="/images/parallogram-small-white.svg" alt=""/> <span className="prefix">Type: {
                                    props.tags.map((tag, index) =>{
                                            return (
                                                <span className="types" key={index}>
                                                {index === props.tags.length-1 ? 
                                                <span>{tag}</span>
                                                : 
                                                <span>{tag},</span>}
                                                </span>
                                            )
                                    })}
                            </span></li>
                        </ul>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-lg-2 offset-lg-3 col-md-3 offset-md-2">
                        <RichText render={props.data.primary.client} linkResolver={linkResolver} />
                        <h4>Key Aspects:</h4>
                        <RichText render={props.data.primary.key_aspects} linkResolver={linkResolver} />
                    </div>
                    <div className="col-md-6">
                        <RichText render={props.data.primary.description} linkResolver={linkResolver} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectBanner;
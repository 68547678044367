import React from 'react';

const LargeHeroImage = (props) => {
    // console.log(props)
        return (
        <div className={"large-hero-image"}>
            {props.data.items.map((item, index)=>{
                return (
                    <img src={item.image.url} alt={item.image.alt} key={index}/>    
                )
            })}
          
        </div>   
    );
}

export default LargeHeroImage;
import React from 'react';

const InstaFeed = (props) => {
    // console.log(props)
    // get the insta token data

    const [ instaData, setInstaData ] = React.useState(null)

    React.useEffect(() => {
        const fetchInstagram = async () => {
            const result = await fetch('https://graph.instagram.com/me/media?fields=media_url,caption&access_token='+props.token)
            const data = await result.json();
            if (data) {
            return setInstaData(data.data)
            } else {
            
            }
        }
        fetchInstagram()
    }, [])


    if(instaData) {
        return (
            <div className="center-wrap">
                <div className="insta-feed">
                    <h3 className=""><a href="https://www.instagram.com/kotahi_engineering_studio/">Follow us on Instagram</a></h3>
                    <div className="wrapper-insta">
                        <div className="row">
                            {instaData.map((item, index)=>{
                                if(index < 6) {
                                return (
                                    <div className="col-2" key={index}>
                                        <a href="https://www.instagram.com/kotahi_engineering_studio/">
                                            <img src={item.media_url}/>
                                        </a>
                                    </div>
                                )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>                 
        )
    }
    return null
}

export default InstaFeed;
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {RichText, linkResolver} from 'prismic-reactjs';
import reactStringReplace from 'react-string-replace';
import { useCookies } from 'react-cookie';
import { Player} from '@lottiefiles/react-lottie-player';
import {ReactComponent as Circle} from '../assets/images/half-circle.svg';
import {ReactComponent as Line} from '../assets/images/parallelogram.svg';
import {ReactComponent as Triangle} from '../assets/images/triangle.svg';

const HeroBanner = (props) => {
    console.log(props)

    const [openState, setOpenState] = useState("");
    const [cookies, setCookie] = useCookies(['animation']);

    const hideAnimations = () => {
        setCookie('animation', false, { path: '/', maxAge: 1296000 });
    };

    let showControls = false;
    let symbols;

    if(props.data.items && props.data.items.length > 1) {
        showControls = true;
    }

    if(props.data.primary.vertical_side_bar !=="") {
        const copy = props.data.primary.vertical_side_bar;
        symbols = reactStringReplace(copy, '[c]', (match, i)=>(
            <Circle />
        ));
        symbols = reactStringReplace(symbols, '[l]', (match, i)=>(
            <Line />
        ));
        symbols = reactStringReplace(symbols, '[t]', (match, i)=>(
            <Triangle />
        ));
    }

    return (
        <>
            <div className={cookies.animation !== 'false' ? "hero-banner "+ openState : "hero-banner animating"}>
                <div className="intro-animation">
                    <div className="content-area">
                        <Player
                            autoplay={true}
                            loop={false}
                            keepLastFrame={true}
                            src={props.data.primary.intro_animation.url}
                            onEvent={(e)=> { 
                                if(e ==="complete") {
                                    hideAnimations()
                                    setOpenState("animating")
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="wrapper">
                    <Carousel controls={showControls} indicators={false}>
                        {props.data.items.map((slide, index) => {
                        return (
                           
                            <Carousel.Item key={index}>
                                <div className="boundaries">
                                    <div className={"shape " + slide.left_hand_shape}></div>
                                    <div className={"shape " + slide.middle_shape}></div>
                                    <div className={"shape " + slide.right_hand_shape}></div>
                                    <div className="vertical">{symbols}</div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img className="large-image" src={slide.half_image.url} alt={slide.half_image.alt} />
                                        </div>
                                        <div className="col-md-5 offset-md-1 content">
                                            {slide.json_title ? 
                                            <>
                                            <div className="horizontal">{symbols}</div>
                                            <div className="animated-heading"> 
                                                <Player
                                                autoplay={true}
                                                loop={true}
                                                src={slide.json_title.url}
                                                />
                                            </div>
                                            </>
                                            : 
                                            <>
                                            <div className="horizontal">{symbols}</div>
                                            <RichText render={slide.headline} linkResolver={linkResolver} /> </>}
                                            <RichText render={slide.description} linkResolver={linkResolver} /> 
                                            {slide.link_text ? 
                                            <p className="link"><a href={slide.link.uid}>{slide.link_text}</a></p>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            
                        )})}
                    </Carousel>
                    
                </div>
            </div>
        </>
    );
}

export default HeroBanner;
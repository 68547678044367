import React, { useRef }from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const ProjectResultImageRollover = (props) => {

    // console.log("render: ", props)
    const imageRef = useRef(null)

    const toggleRollOver = (e) => {
        e.currentTarget.childNodes[1].classList.toggle("open")
    }

    return (
        <div className="content-area" onMouseOver={(e)=>{toggleRollOver(e)}} onMouseOut={(e)=>{toggleRollOver(e)}}  onClick={()=>{window.location.href=props.url}}>
            <div className="image-area" ref={imageRef}>
                {props.data.seo_image ? 
                <img src={props.data.seo_image.url} alt={props.data.seo_image.alt} />
                : null }
            </div>
            <div className={"rollover-content "+props.state}>
                <h3>{props.data.seo_title}</h3>
                {/* <h4>Key Aspects</h4>
                <RichText render={props.data.body[0].primary.key_aspects} linkResolver={linkResolver} />                */}
            </div>
        </div>
    );
}

export default ProjectResultImageRollover;
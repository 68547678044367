import React from 'react';

const ContactUsLink = (props) => {
    console.log(props)

    return (
        <div className={"project-team contact-us-link " + (props.data.primary.background === true ? "blue" : "ivory")}>
            <div className="wrapper row">
                <div className="col-md-3 ">
                </div>
                <div className="col-md-8 ">
                    <h3 className="contact-link "><a href={props.data.primary.link?.uid ? props.data.primary.link.uid : props.data.primary.link?.url}>{props.data.primary.link_text}</a></h3>
                </div>
            </div>
        </div>
    );
}

export default ContactUsLink;

import React from 'react';
import { RichText, linkResolver } from 'prismic-reactjs';
import { ProjectImageRollover } from './index'

const ProjectImage = (props) => {
    // console.log(props)
        if(props.data.items.length>0) {

        let item = props.data.items;

        return (
        <div className="project-panel">
            <div className="wrapper">
                {props.data.primary.template_type === "Small, Large" ? 
                <div className="row">
                    <div className="col-md-4">
                        <img src={item[0]?.image?.projectsearch?.url} alt={item[0]?.image?.projectsearch?.alt}/>
                    </div>
                    <div className="col-md-7 offset-md-1">
                    <img src={item[1]?.image?.projectsearch?.url} alt={item[1]?.image?.projectsearch?.alt}/>
                    </div>
                </div>
                : null }
                {props.data.primary.template_type === "Large, Small" ?
                <div className="row">
                    <div className="col-md-7">
                        <img src={item[0]?.image?.projectsearch?.url} alt={item[0]?.image?.projectsearch?.alt}/>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <img src={item[1]?.image?.projectsearch?.url} alt={item[1]?.image?.projectsearch?.alt}/>
                    </div>
                </div>
                : null }
                {props.data.primary.template_type === "Full Width" ?
                <div className="row">
                    <div className="col-12">
                        <img src={item[0].image.url} alt={item[0].image.alt}/>
                    </div>
                </div>
                : null }
                {props.data.primary.template_type === "Two Thirds" ?
                <div className="row">
                    <div className="col-md-8">
                        <img src={item[0]?.image?.projectsearch?.url} alt={item[0]?.image?.projectsearch?.alt}/>
                    </div>
                </div>
                : null }
                {props.data.primary.template_type === "Portrait Large" ?
                <div className="row">
                    <div className="col-md-4">
                        <img src={item[0]?.image?.Portrait?.url} alt={item[0]?.image?.Portrait?.alt}/>
                    </div>
                    <div className="offset-md-1 col-md-7">
                        <img src={item[1]?.image?.projectsearch?.url} alt={item[1]?.image?.projectsearch?.alt}/>
                    </div>
                </div>
                : null }
                {props.data.primary.template_type === "Portrait Portrait" ?
                <div className="row">
                    <div className="col-md-4">
                        <img src={item[0]?.image?.Portrait?.url} alt={item[0]?.image?.Portrait?.alt}/>
                    </div>
                    <div className="col-md-4 end">
                        <img src={item[1]?.image?.Portrait?.url} alt={item[1]?.image?.Portrait?.alt}/>
                    </div>
                </div>
                : null }
            </div>
        </div>
    );
        }
        else {
            return null
        }
}

export default ProjectImage;
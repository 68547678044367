import React, { useState, useEffect } from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';
import { client} from '../prismic-configuration'
import Prismic from 'prismic-javascript'

const OurTeam = (props) => {
    // console.log(props)
    const [people, setPeople] = useState(null)

    const openBio = (e) => {
            e.currentTarget.previousSibling.classList.toggle("open");
            e.currentTarget.classList.toggle("open");
            
            if(e.currentTarget.classList.contains("open")) {
                e.currentTarget.innerText="Read Less";
            }
            else {
                e.currentTarget.innerText="Read More";
            }
            
    }

    const uid="kotahi"

    // Get the page document from Prismic
    useEffect(() => {
        const fetchProjects = async () => {
            const response = await client.query(
                Prismic.Predicates.at('document.type', 'people'), { orderings : '[my.people.list_order]', pageSize: 100 }
            )
            if (response) {
                // console.log(response.results)
                return setPeople(response.results)
            }
        }
        fetchProjects()
    }, [uid]) // Skip the Effect hook if the UID hasn't changed
     
    if(people) {
    return (
        <div className="project-team">
            <div className="wrapper">
                        <ul className="team-list">
                        {people.map((item, index)=>{
                            return (
                                <li className="team" key={index}>
                                    <div className="imageset">
                                        <img src={item.data.persons_image.url} alt={item.data.persons_image.alt} />
                                        <img className="rollover" src={item.data.rollover_image.url} alt={item.data.rollover_image.alt} />
                                    </div>
                                    <div className="bio-wrapper">
                                        <h4>{item.data.name}</h4>
                                        {item.data.position ?
                                        <h5>{item.data.position}</h5>
                                        : null }
                                        <div className="subtext">
                                            {item.data.qualifications ?
                                            <p className="qualifications"><span className="prefix">Qualifications: </span> {item.data.qualifications}</p>
                                            : null }
                                            {item.data.location ? 
                                            <p className="location"><span className="prefix">Location: </span>{item.data.location}</p>
                                            : null }
                                        </div>
                                        <div className="bio area">
                                            <div className="bio-content">
                                                <RichText render={item.data.bio} linkResolver={linkResolver} />
                                            </div>
                                            <div className="read-more" onClick={(e)=>openBio(e)}>Read More</div>
                                        </div>   
                                    </div> 
                                </li>
                                
                            )
                        })}
                </ul>
                <h3 className="contact-link"><a href="/get-in-touch">Contact us about your project</a></h3>
            </div>
        </div>
    );
    }
    else {
        return null
    }
}


export default OurTeam;
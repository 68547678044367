import React from 'react';
import { RichText, linkResolver } from 'prismic-reactjs';
import { ProjectImageRollover } from './index'

const ProjectPanel = (props) => {
    // console.log(props)
        if(props.data.items.length <= 3) {

        let item = props.data.items;

        const paddingBottom = props.data.primary.last_panel == true ? "last-panel" : ""
        return (
        <div className={"project-panel "+paddingBottom}>
            <div className="wrapper">
                { props.data.primary.panel_type === "Large, Small, Small" ? 
                        <div className="row l-s-s relative">
                            {item[0] ?
                            <div className="col-lg-6 large-project">
                                <ProjectImageRollover data={item[0]} url={'/projects/'+item[0].project_link.uid}/>
                                <div className="caption d-sm-block d-md-block d-lg-none">
                                    <p><a href={'/projects/'+item[0].project_link.uid}>PROJECT: {item[0].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[1] ?
                            <div className="col-lg-3 small-project-1">
                                <ProjectImageRollover data={item[1]} url={'/projects/'+item[1].project_link.uid}/>
                                <div className="caption">
                                    <p><a href={'/projects/'+item[1].project_link.uid}>PROJECT: {item[1].client_name}</a></p>
                                </div>
                                <div className="caption bottom d-none d-lg-block">
                                    <p><a href={'/projects/'+item[0].project_link.uid}>PROJECT: {item[0].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[2] ?
                            <div className="col-lg-3 small-project-2">
                                
                                <ProjectImageRollover data={item[2]} url={'/projects/'+item[2].project_link.uid}/>
                                <div className="caption">
                                    <p><a href={'/projects/'+item[2].project_link.uid}>PROJECT: {item[2].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {props.data.primary.link?.uid || props.data.primary.link?.url ?
                            <h3 className="contact-link" style={{left: '75%'}}><a href={props.data.primary.link?.uid ? props.data.primary.link.uid : props.data.primary.link?.url}>{props.data.primary.link_text}</a></h3>
                            : null }
                        </div>
                    : null }

                    { props.data.primary.panel_type === "Small, Small, Large" ?
                        <div className="row s-s-l relative">
                            {item[0] ?
                            <div className="col-lg-3 small-project-1">
                                <ProjectImageRollover data={item[0]} url={'/projects/'+item[0].project_link.uid}/>
                                <div className="caption">
                                    <p><a href={'/projects/'+item[0].project_link.uid}>PROJECT: {item[0].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[1] ?
                            <div className="col-lg-3 small-project-2">
                                <ProjectImageRollover data={item[1]} url={'/projects/'+item[1].project_link.uid}/>
                                <div className="caption">
                                    <p><a href={'/projects/'+item[1].project_link.uid}>PROJECT: {item[1].client_name}</a></p>
                                </div>
                                <div className="caption bottom right d-none d-lg-block">
                                    <p><a href={'/projects/'+item[2].project_link.uid}>PROJECT: {item[2].client_name}</a></p>
                                </div>
                            </div>
                            : null}
                            {item[2] ?
                            <div className="col-lg-6 large-project">
                                <ProjectImageRollover data={item[2]} url={'/projects/'+item[2].project_link.uid}/>
                                <div className="caption d-sm-block d-md-block d-lg-none">
                                    <p><a href={'/projects/'+item[2].project_link.uid}>PROJECT: {item[2].client_name}</a></p>
                                </div>
                            </div>
                            : null }
                            {props.data.primary.link?.uid || props.data.primary.link?.url ?
                            <h3 className="contact-link" style={{maxWidth: '450px', left:'4px', paddingLeft: '0px'}}><a style={{maxWidth: '450px', left:'4px', paddingLeft: '34px', display: 'block'}} href={props.data.primary.link?.uid ? props.data.primary.link.uid : props.data.primary.link?.url}>{props.data.primary.link_text}</a></h3>
                            : null }
                        </div>
                    : null }
                    { props.data.primary.panel_type === "Large, Small Description Open, Small" ?
                        <div className="row l-s-s relative">
                            {item[0] ?
                            <div className="col-lg-6 large-project">
                                <ProjectImageRollover data={item[0]} url={'/projects/'+item[0].project_link.uid}/>
                                <div className="caption d-sm-block d-md-block d-lg-none">
                                    <p><a href={'/projects/'+item[0].project_link.uid}>PROJECT: {item[0].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[1] ?
                            <div className="col-lg-3 small-prject-1">
                                <ProjectImageRollover data={item[1]} state="open" url={'/projects/'+item[1].project_link.uid}/>
                                <div className="caption">
                                    <p><a href={'/projects/'+item[1].project_link.uid}>PROJECT: {item[1].client_name}</a></p>
                                </div>
                                <div className="caption bottom d-none d-lg-block">
                                    <p><a href={'/projects/'+item[0].project_link.uid}>PROJECT: {item[0].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[2] ?
                            <div className="col-lg-3 small-project-2">
                                <ProjectImageRollover data={item[2]} url={'/projects/'+item[2].project_link.uid}/>
                                <p><a href={'/projects/'+item[2].project_link.uid}>PROJECT: {item[2].client_name}</a></p>
                            </div>
                            : null }
                            {props.data.primary.link?.uid || props.data.primary.link?.url ?
                                <h3 className="contact-link" style={{maxWidth: '450px', left:'4px', paddingLeft: '0px'}}><a style={{maxWidth: '450px', left:'4px', paddingLeft: '34px', display: 'block'}} href={props.data.primary.link?.uid ? props.data.primary.link.uid : props.data.primary.link?.url}>{props.data.primary.link_text}</a></h3>
                            : null }
                        </div>
                    : null }
                    { props.data.primary.panel_type === "Small, Small, Large Description Open" ?
                        <div className="row s-s-l relative">
                            {item[0] ?
                            <div className="col-lg-3">
                                <ProjectImageRollover data={item[0]} url={'/projects/'+item[0].project_link.uid}/>
                                <div className="caption">
                                    <p><a href={'/projects/'+item[0].project_link.uid}>PROJECT: {item[0].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[1] ?
                            <div className="col-lg-3">
                                <ProjectImageRollover data={item[1]} url={'/projects/'+item[1].project_link.uid}/>
                                <div className="caption">
                                    <p><a href={'/projects/'+item[1].project_link.uid}>PROJECT: {item[1].client_name}</a></p>
                                </div>
                                <div className="caption bottom right d-none d-lg-block">
                                    <p><a href={'/projects/'+item[2].project_link.uid}>PROJECT: {item[2].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[2] ?
                            <div className="col-md-6">
                                <ProjectImageRollover data={item[2]} state="open" url={'/projects/'+item[2].project_link.uid}/>
                                <div className="caption d-sm-block d-md-block d-lg-none">
                                    <p><a href={'/projects/'+item[2].project_link.uid}>PROJECT: {item[2].client_name}</a></p>
                                </div>
                            </div>
                            : null }
                            {props.data.primary.link?.uid || props.data.primary.link?.url ?
                                <h3 className="contact-link"><a style={{maxWidth: '450px', left:'4px', paddingLeft: '34px', display: 'block'}} href={props.data.primary.link?.uid ? props.data.primary.link.uid : props.data.primary.link?.url}>{props.data.primary.link_text}</a></h3>
                            : null }
                        </div>
                    : null }
                    { props.data.primary.panel_type === "Portrait, Portrait, Blank" ?
                        <div className="row s-s-l relative">
                            {item[0] ?
                            <div className="col-lg-3 small-project-1">
                                <ProjectImageRollover data={item[0]} url={'/projects/'+item[0].project_link.uid} />
                                <div className="caption">
                                    <p><a href={'/projects/'+item[0].project_link.uid}>PROJECT: {item[0].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[1] ?
                            <div className="col-lg-3 end small-project-2">
                                <ProjectImageRollover data={item[1]} url={'/projects/'+item[1].project_link.uid} />
                                <div className="caption">
                                    <p><a href={'/projects/'+item[1].project_link.uid}>PROJECT: {item[1].client_name}</a></p>
                                </div>
                                <div className="caption bottom right d-none d-lg-block">
                                    <p><a href={'/projects/'+item[2].project_link.uid}>PROJECT: {item[2].client_name}</a></p>
                                </div>
                            </div>
                            : null}
                        </div>
                    : null }
                    { props.data.primary.panel_type === "Portrait, Blank, Large" ?
                        <div className="row s-s-l relative">
                            {item[0] ?
                            <div className="col-lg-3 end small-project-1">
                                <ProjectImageRollover data={item[0]} url={'/projects/'+item[0].project_link.uid} />
                                <div className="caption">
                                    <p><a href={'/projects/'+item[0].project_link.uid}>PROJECT: {item[0].client_name}</a></p>
                                </div>
                            </div>
                            :null}
                            {item[1] ?
                            <div className="col-lg-6 offset-md-3 small-project-2">
                                <ProjectImageRollover data={item[1]} url={'/projects/'+item[1].project_link.uid} />
                                <div className="caption">
                                    <p><a href={'/projects/'+item[1].project_link.uid}>PROJECT: {item[1].client_name}</a></p>
                                </div>
                                <div className="caption bottom right d-none d-lg-block">
                                    <p><a href={'/projects/'+item[2].project_link.uid}>PROJECT: {item[2].client_name}</a></p>
                                </div>
                            </div>
                            : null}
                        </div>
                    : null }
                </div>
        </div>
    );
        }
        else {
            return null
        }
}

export default ProjectPanel;
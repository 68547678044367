import React, {useRef, useState } from 'react';
import {ReactComponent as MenuBars} from '../assets/images/menu-bars.svg';

const Header = (props) => {
    // console.log(props)

    const [menuState, toggleMenuState] = useState("")
    
    const toggleMenu = () => {
        if(menuState === "open") {
            toggleMenuState("");
        }
        else {
            toggleMenuState("open");
        }
        return false;
    }

    return (
        <div className={"header " +props.stickyState}>
            <div className="wrapper">
            <div className="menu-bars" onClick={toggleMenu}><MenuBars /></div>
                <div className="row">
                    <div className="col-md-6">
                        <a href="/"><img className="logo" src={props.data.data.logo.url} alt={props.data.data.logo.alt} /></a>
                    </div>
                    <div className="col-md-5 offset-md-1 header-right">
                        <ul className={"navigation "+menuState}  onClick={toggleMenu}>
                            {props.nav.results.map((section, index) => {
                                if(section.uid !=="home" && section.uid !=="privacy-policy" && section.data.hide_in_menu !== true) {
                                    return (
                                        <li key={index}><a href={"/"+section.uid}>{section.data.section_name}</a></li>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
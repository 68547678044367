import React, { Fragment } from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { apiEndpoint } from './prismic-configuration'
import { Project, Section, Preview} from './pages'
import { CookiesProvider } from "react-cookie";
import './assets/kotahi.scss';

/**
 * Main application componenet
 */
const App = (props) => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
  const repoName = repoNameArray[1]

  return (
    <CookiesProvider>
      <Fragment>
        <BrowserRouter>
          <Switch>
            <Redirect exact from='/' to='/home' />
            <Route exact path='/preview' component={Preview} />
            <Route exact path='/:uid' component={Section} />
            <Route exact path='/projects/:uid' component={Project} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    </CookiesProvider>
  )
}

export default App

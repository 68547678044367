// In src/pages/Page.js
import React, { useEffect, useState,Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { client} from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import {HeroBanner, TwoColumnPanel, ProjectPanel, BreakOutPanel, ContactForm, SlimIntroPanel, LocationsPanel, LargeHeroImage, OurTeam, Projects, RelatedProjects, Header, Footer, Positions, InstaFeed, ContactUsLink} from '../components'

const Section = ({ match }) => {
  const [doc, setDocData] = useState(null)
  const [core, setCoreData] = useState(null)
  const [navItems, setNavItems] = useState(null)
  const [sticky, setSticky] = useState("")
  const [stickyFilter, setStickyFilter] = useState("")
  const uid = match.params.uid
  // Get the page document from Prismic
  useEffect(() => {

    // scrolly rolly magic
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 100){
        setSticky("sticky")
      } else {
        setSticky("")
      }
      if (scrolled >= 600){
        setStickyFilter("sticky-filter")
      } else {
        setStickyFilter("")
      }
    })

    const fetchCore = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('header_footer', 'header-footer-menu')
      if (result) {
        // We use the State hook to save the document
        // console.log('core: ', result)
        return setCoreData(result)
      } else {
        // Otherwise show an error message
        // console.log('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchCore()
    const fetchDoc = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('section', uid)
      if (result) {
        // console.log('doc: ', result)
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        // console.log('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchDoc()
    const fetchNavItems = async () => {
      const response = await client.query(
        Prismic.Predicates.at('document.type', 'section'), { orderings: '[my.section.page_order]'}
      )
      if (response) {
        return setNavItems(response)
      }
    }
    fetchNavItems()
  }, [uid]) // Skip the Effect hook if the UID hasn't changed

  if (core && doc && navItems) {
    let dataSet=[];

    doc.data.body.map((component, index) =>{
      switch(component.slice_type) {
        case "hero_banner":
          dataSet.push(<HeroBanner data={component} key={index} />)
        break
        case "project_panel":
          dataSet.push(<ProjectPanel data={component} key={index} />)
        break
        case "breakout_panel":
          dataSet.push(<BreakOutPanel data={component} key={index} />)
        break
        case "slim_intro_panel":
          dataSet.push(<SlimIntroPanel data={component} key={index} doc={doc.data}/>)
        break
        case "two_column_panel":
          dataSet.push(<TwoColumnPanel data={component} key={index} />)
        break
        case "project_filter":
          dataSet.push(<Projects data={component} key={index} sticky={stickyFilter}/>)
        break
        case "large_hero_image":
          dataSet.push(<LargeHeroImage data={component} key={index} />)
        break
        case "locations_panel":
          dataSet.push(<LocationsPanel data={component} key={index} />)
        break
        case "our_team":
          dataSet.push(<OurTeam data={component} key={index} />)
        break
        case "projects":
          dataSet.push(<Projects data={component} key={index} sticky={stickyFilter}/>)
        break
        case "positions_available":
          dataSet.push(<Positions data={component} key={index} />)
        break
        case "contact_form":
          dataSet.push(<ContactForm data={component} key={index} />)
        break
        case "contact_us_link":
          dataSet.push(<ContactUsLink data={component} key={index} />)
        break
        default:
      }
    })
    
    return (
      <Fragment>
        <Helmet>
          <meta property="og:title" content={doc.data.seo_title}/>
          <meta property="og:description" content={doc.data.seo_description} />
          <meta property="og:image" content={doc.data.seo_image.url}/>
          <meta property="og:url" content={window.location.href}/>
          <meta name="twitter:card" content={doc.data.seo_image.alt} />
          <meta property="og:site_name" content={doc.data.seo_title}/>
          <meta name="twitter:image:alt" content={doc.data.seo_image.alt}/>
          <title>{doc.data.seo_title}</title>
        </Helmet>
        <Header data={core} nav={navItems} stickyState={sticky}/>
        <div className="outer-wrapper">
          {dataSet.map((module, index)=>{
            return module
          })}
        </div>
        {doc.data.show_instagram_feed === true ? 
        <InstaFeed token={core.data.instagram_token}/>
        : null }
        <Footer data={core} />
      </Fragment>
    )
  } 
  else {
      return null
  }
}

export default Section
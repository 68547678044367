import React, {useRef, useState, useEffect }from 'react';
import { ProjectResultImageRollover } from './index'
import {RichText, linkResolver} from 'prismic-reactjs';
import { client} from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import _, { identity } from 'lodash'

const RelatedProjects = (props) => {
   
   
    const dataSet = props.data;

    const [related1, setRelated1] = useState("");
    const [related2, setRelated2] = useState("");

    const uid="kotahi"

    console.log(dataSet.items)

    // Get the page document from Prismic
    useEffect(() => {
        const fetchRelated1 = async () => {
            // We are using the function to get a document by its UID
            // console.log(dataSet.items[1].project_link.uid)
            const result1 = await client.getByUID('projects', dataSet.items[0].project_link.uid)
            if (result1) {
              // We use the State hook to save the document
              setRelated1(result1)
            } else {
              // Otherwise show an error message
              // console.log('Page document not found. Make sure it exists in your Prismic repository')
            }

            
            const result2 = await client.getByUID('projects',  dataSet.items[1].project_link.uid)
            
            if (result2) {
              // console.log('doc: ', result2)
              // We use the State hook to save the document
              setRelated2(result2)
            } else {
              // Otherwise show an error message
              // console.log('Page document not found. Make sure it exists in your Prismic repository')
            }
          }
        fetchRelated1();

        // const fetchRelated2 = async () => {
        //     // We are using the function to get a document by its UID
        //     // console.log(dataSet.items[1].project_link.uid)
        //     const result2 = await client.getByUID('projects',  dataSet.items[1].project_link.uid)
            
        //     if (result2) {
        //       // console.log('doc: ', result2)
        //       // We use the State hook to save the document
        //       return setRelated2(result2)
        //     } else {
        //       // Otherwise show an error message
        //       // console.log('Page document not found. Make sure it exists in your Prismic repository')
        //     }
        //   }
        // fetchRelated2();

    }, [uid]) // Skip the Effect hook if the UID hasn't changed

        return (
            <div className="related-projects">
                <div className="row">
                    <div className="col-md-3 offset-md-6">
                    {related1 ? 
                        <h4>Related Projects</h4>
                    : null }
                     </div>
                </div>
                <div className="row">
                    <div className="col-md-3 offset-md-6">
                    {related1 ? 
                        <div className= "related-panel">
                            <img src={related1.data.seo_image.url} alt={related1.data.seo_image.alt}/>
                            <div className="caption">
                                <p><a href={'/projects/'+related1.uid}>PROJECT: {related1.data.seo_title}</a></p>
                            </div>
                        </div>
                        : null}
                    </div>
                    <div className="col-md-3">
                    {related2 ? 
                        <div className= "related-panel">
                            <img src={related2.data.seo_image.url} alt={related2.data.seo_image.alt}/>
                            <div className="caption">
                                <p><a href={'/projects/'+related2.uid}>PROJECT: {related2.data.seo_title}</a></p>
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        </div>
        );
    
}

export default RelatedProjects
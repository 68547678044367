import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';
import ReactPlayer from 'react-player'
import { scrollIntoView } from "seamless-scroll-polyfill";

const SlimIntroPanel = (props) => {
        
        const jumpLinks =[]

        const scrollToBlock = (id) =>{ 
            document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        };

        if(props.doc.body?.length > 0 && props.doc.show_inpage_links === true) {
            props.doc.body?.map((item)=>{
                if(item.primary.in_page_link && item.primary.in_page_link === true) {
                    jumpLinks.push(<span className="in-page-link" onClick={()=>scrollToBlock(item.id)}><RichText render={item.primary.headline} linkResolver={linkResolver} /></span>)
                }
            })
           
        }

        const videoRef = React.useRef(null)

        const padbelow = props.data.primary.padding_below === true ? "padding-below" : "";

        return (
        <div className={"slim-intro-panel "+props.data.primary.background.toLowerCase()+" "+padbelow+ " "+props.data.primary.include_shape_} id={props.data.id}>
            <div className="row">
                <div className={props.data.primary.video_link?.url ? "col-md-6 col-xl-4 offset-xl-1" : "col-md-3 offset-md-2 col-xl-2 offset-xl-3"} ref={videoRef}>
                    {props.data.primary.video_link?.url ?
                        <div className="video-panel">
                            <ReactPlayer    url={props.data.primary.video_link.url} 
                                            className='react-player'
                                            width='100%'
                                            playsinline={true}
                            />
                        </div>
                    :
                    <RichText render={props.data.primary.headline} linkResolver={linkResolver} />
                    }
                </div>
                <div className={props.data.primary.video_link?.url ? "col-md-6 slim-intro-panel-text" : "col-md-7 slim-intro-panel-text" }>
                    {props.data.primary.video_link?.url && props.data.primary.headline.length > 1 ?
                    <RichText render={props.data.primary.headline} linkResolver={linkResolver} />
                    : null}
                    <RichText render={props.data.primary.description} linkResolver={linkResolver} />
                    {props.data.primary.link?.link_type !=="Any" ? 
                        <a className="byline uppercase" href={props.data.primary.link?.url ? props.data.primary.link.url : props.data.primary.link?.uid}>{props.data.primary.link_text}</a>
                    : null }
                    {jumpLinks?.length > 0  && props.data.id === props.doc.body[0]?.id ? 
                        <>
                            <ul>
                                {jumpLinks.map((item, index)=>{
                                    return (
                                        <li key={index}>{item}</li>
                                    )
                                })}
                            </ul>
                        </>
                    : null}
                </div>
            </div>
        </div>   
    );
}

export default SlimIntroPanel;
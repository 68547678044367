import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const LocationsPanel = (props) => {
    // console.log(props.data.items)
    return (
        <div className="locations-panel">
            <div className="row">
                <div className="col-md-9 offset-md-2 col-xl-9 offset-xl-3">
                    <div className="row">
                        {props.data.items.map((item, index) =>{
                            return(
                            <div className={index===0 ? "col-md-4"  : "col-md-4"} key={index}> 
                                <RichText render={item.location} linkResolver={linkResolver} />
                                <ul className="location-details">
                                    <li className="phone"><span className="prefix">Phone: </span><a href={item.phone.url}>{item.phone.url.replace("tel:","").trim()}</a></li>
                                    <li className="email"><span className="prefix">Email: </span><a href={item.email.url}>{item.email.url.replace("mailto:","").trim()}</a></li>
                                    <li className="address"><span className="prefix">Address: </span>{RichText.asText(item.address)}</li>
                                </ul>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
           
        </div>
    )
}
export default LocationsPanel;
// In src/pages/Page.js
import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import {RichText, linkResolver} from 'prismic-reactjs';

const ContactForm = (props) => {
    // console.log(props.data)
    const [success, setSuccess] = useState(null);
    const UPLOAD_URL = props.data.primary.form_action;
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        axios
          .post(UPLOAD_URL, data)
          .then((res) => {
            setSuccess(true)
          })
          .catch((err) => setSuccess(false));
      };
    //   console.log(props)

    const field = props.data.items;
    return (
        <div className="contact-form" id="register">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-xl-6 offset-xl-3">
                    <RichText render={props.data.primary.heading}/>
                    {success === false || success == null ?
                        <div className="form-area">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label >
                                            <input placeholder={field[0].field_title} type={field[0].field_type} name={field[0].field_name} className="required" ref={register({required: field[0].field_required})}/>
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label >
                                            <input placeholder={field[1].field_title} type={field[1].field_type} name={field[1].field_name} className="required" ref={register({required: field[1].field_required})}/>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label >
                                            <input placeholder={field[2].field_title} type={field[2].field_type} name={field[2].field_name} className="required" ref={register({required: field[2].field_required})}/>
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label >
                                            <input placeholder={field[3].field_title} type={field[3].field_type} name={field[3].field_name} className="required" ref={register({required: field[3].field_required})}/>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label >
                                            <textarea placeholder={field[4].field_title} ref={register({required: field[4].field_required})} className="required"  name={field[4].field_name} rows="8"></textarea>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label >
                                            <input className="submit" type={field[5].field_type} name={field[5].field_name} value={field[5].field_title} ref={register}/>
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        :   
                        <div className="success">
                            <h3>Thank you for contacting us</h3>
                        </div> 
                        }    
                </div>
            </div>
        </div>
    )
}
export default ContactForm
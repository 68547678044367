import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const BreakOutPanel = (props) => {
        // console.log('test: ', props)
        return (
        <div className="break-out-panel">
            <div className="wrapper">
                <div className="row">
                    <div className={props.data.primary.shapes === true ? "col-md-6 shapes" : "col-md-6"}>
                        <div className="row">
                            <div className="col-md-12 img-wrap">
                                <img src={props.data.primary.image.url} alt={props.data.primary.image.alt} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 break-out-panel-text">
                        <RichText render={props.data.primary.heading} linkResolver={linkResolver} />
                        <RichText render={props.data.primary.description} linkResolver={linkResolver} />
                        <p><a href={props.data.primary.link.uid ? props.data.primary.link.uid : props.data.primary.link.url}>{props.data.primary.link_text}</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BreakOutPanel;
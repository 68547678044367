import { useEffect } from 'react';
import qs from 'qs';
import Prismic from '@prismicio/client';
import { linkResolver } from '../prismic-configuration';

const apiEndpoint = 'https://kotahi-web.cdn.prismic.io/api/v2';
const client = Prismic.client(apiEndpoint);
const Preview = ({ history, location }) => {
  useEffect(() => {
    const {token, documentId} = qs.parse(location.search.slice(1));
    if (!token) {
      return console.warn(`No token available, check your configuration`);
    } 
    client.getPreviewResolver(token, documentId).resolve(linkResolver, '/').then(url => history.push(url));
    });
  return null;
};

export default Preview;